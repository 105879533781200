<template>
  <v-container fluid class="sys-page">
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card>
          <template v-slot:heading>
            <v-row no-gutters>
              <v-col cols="10">
                <v-tabs
                  v-model="tabs"
                  background-color="transparent"
                  slider-color="white"
                >
                  <span
                    class="subheading font-weight-light mx-3"
                    style="align-self: center"
                    >Select Options:</span
                  >
                  <v-tab class="mr-3">
                    <v-icon class="mr-2">
                      mdi-image-filter-vintage
                    </v-icon>
                    Orders
                  </v-tab>
                  <v-tab class="mr-3">
                    <v-icon class="mr-2">
                      mdi-checkbox-multiple-blank
                    </v-icon>
                    Cost
                  </v-tab>
                </v-tabs>
              </v-col>
                <v-col cols="2" class="d-flex align-center justify-end">
                  <v-btn color="#2979ff" elevation="4" @click="exportPDF">
                    <v-icon left> mdi-file-export </v-icon>
                    {{ $vuetify.lang.t("$vuetify.export") }}
                  </v-btn>
                </v-col>
              </v-row>
          </template>

          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Select Month"
                prepend-icon="mdi-calendar"
                class="mt-4"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-tabs class="mt-4" fixed-tabs v-if="tabs == 0" v-model="activeTab">
            <v-tab
              ><v-icon left>mdi-currency-usd</v-icon>United State Dollar
              (USD)</v-tab
            >
            <v-tab><v-icon left>mdi-currency-cny</v-icon>Renminbi (RMB)</v-tab>
            <v-tab><v-icon left>mdi-truck</v-icon>Shipping</v-tab>

            <v-tab-item>
              <StatisticalTableOrder
                title="United State Dollar (USD)"
                mode="Order"
                :datas="OrderObj.USDObj"
                class="USDTable"
              />
            </v-tab-item>
            <v-tab-item>
              <StatisticalTableOrder
                title="Renminbi(RMB)"
                mode="Order"
                :datas="OrderObj.RMBObj"
                class="RMBTable"
              />
            </v-tab-item>
            <v-tab-item>
              <StatisticalTableOrder
                title="Shipping"
                :datas="OrderObj.ShippingObj"
                class="ShipTable"
              />
            </v-tab-item>
          </v-tabs>

          <v-tabs class="pt-4" fixed-tabs v-if="tabs == 1" v-model="activeTab">
            <v-tab> <v-icon left>mdi-cash-usd</v-icon>Sale Order </v-tab>
            <v-tab> <v-icon left>mdi-cash</v-icon>Purchase Order </v-tab>

            <v-tab-item>
              <StatisticalTableCost
                title="Sale Order"
                mode="Sale"
                :datas="CostObj.SaleObj"
                class="SaleTable"
              />
            </v-tab-item>
            <v-tab-item>
              <StatisticalTableCost
                title="Purchase Order"
                mode="Purchase"
                :datas="CostObj.PurchaseObj"
                class="PurchaseTable"
              />
            </v-tab-item>
          </v-tabs>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { orderStat } from "@/api/financial";
import { purchaseList } from "@/api/purchase";
import { sellList, supplierList, customerList } from "@/api/basicdata";
import serverConfig from "@/utils/serverConfig";
import {
  orderList,
  orderComplaintList,
  listOrderSend,
  purchaseOrderStat
} from "@/api/order";
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import $ from 'jquery';

export default {
  name: "StatisticalData",
  components: {
    StatisticalTableOrder: () =>
      import("@/components/financial/statisticalTableOrder"),
    StatisticalTableCost: () =>
      import("@/components/financial/statisticalTableCost"),
    PageNavigation: () => import("@/components/pageNavigation")
  },
  data() {
    return {
      activeTab: 0,
      wait: [],
      tempCustomer: [],
      tempOrder: [],
      tempResult: [],
      searchClient: {
        ids: "",
        skip: 0,
        limit: 9999,
        currency: "",
        bt: "",
        et: ""
      },
      date: new Date().toISOString().substr(0, 7),
      modal: false,
      tabs: 0,
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchaseOrderStatistics") }
      ],
      OrderObj: {
        USDObj: {
          Month: 0,
          AmountObj: {
            saleAmount: 0,
            shippedAmount: 0,
            unshippedAmount: 0,
            complaintAmount: 0
          },
          Orderobj: {
            saleOrder: 0,
            shippedOrder: 0,
            unshippedOrder: 0,
            complaintOrder: 0
          },
          SaleObj: {
            saleSale: 0,
            shippedSale: 0,
            unshippedSale: 0,
            complaintSale: 0,
            receivedpaymentSale: 0,
            unpaySale: 0
          }
        },
        RMBObj: {
          Month: 0,
          AmountObj: {
            saleAmount: 0,
            shippedAmount: 0,
            unshippedAmount: 0,
            complaintAmount: 0
          },
          Orderobj: {
            saleOrder: 0,
            shippedOrder: 0,
            unshippedOrder: 0,
            complaintOrder: 0
          },
          SaleObj: {
            saleSale: 0,
            shippedSale: 0,
            unshippedSale: 0,
            complaintSale: 0,
            receivedpaymentSale: 0,
            unpaySale: 0
          }
        },
        ShippingObj: {
          Month: 0,
          territory: 0,
          abroad: 0,
          total: 0
        }
      },
      CostObj: {
        SaleObj: {
          Month: 0,
          USD: {
            sale: 0,
            order: 0,
            amount: 0,
            customer: 0
          },
          RMB: {
            sale: 0,
            order: 0,
            amount: 0,
            customer: 0
          }
        },
        PurchaseObj: {
          Month: 0,
          sale: 0,
          order: 0,
          amount: 0,
          supplier: 0,
          saleDiscount: 0,
          shipping: 0,
          profit: 0
        }
      }
    };
  },
  methods: {
    getData() {
      // orderStat()
      //   .then(res => console.log("Order", res))
      //   .catch(err => console.log("Order Err", err));

      // purchaseList()
      //   .then(res => console.log("Purchase", res))
      //   .catch(err => console.log("Purchase Err", err));

      // sellList()
      //   .then(res => console.log("Sell", res))
      //   .catch(err => console.log("Sell Err", err));

      // supplierList()
      //   .then(res => console.log("Supplier", res))
      //   .catch(err => console.log("Supplier Err", err));

      // customerList(this.searchClient)
      //   .then(res => {
      //     this.tempCustomer = [...res.items];

      //     this.wait.push(true);
      //   })
      //   .catch(err => console.log("Customer Err", err));

      // orderList()
      //   .then(res => {
      //     this.tempOrder = [...res.items];

      //     this.wait.push(true);
      //   })
      //   .catch(err => console.log("Order List Err", err));

      // orderComplaintList()
      //   .then(res => console.log("Order Complaint", res))
      //   .catch(err => console.log("Order Complaint Err", err));

      orderStat()
        .then(res => {
          res.item.all.filter(p => p.id.month != this.date).length ==
          res.item.all.length
            ? this.initBlankObj()
            : this.initUSDObj(res.item);
        })
        .catch(err => console.log("Statistical Error", err));
    },
    initUSDObj(data) {
      this.OrderObj.USDObj.Month = data.all.filter(
        p => p.id.month == this.date
      )[0].id.month;

      this.OrderObj.RMBObj.Month = data.all.filter(
        p => p.id.month == this.date
      )[0].id.month;

      this.OrderObj.ShippingObj.Month = data.all.filter(
        p => p.id.month == this.date
      )[0].id.month;

      this.CostObj.SaleObj.Month = data.all.filter(
        p => p.id.month == this.date
      )[0].id.month;

      this.CostObj.PurchaseObj.Month = data.all.filter(
        p => p.id.month == this.date
      )[0].id.month;

      this.OrderObj.USDObj.Orderobj.saleOrder = data.all.filter(
        p => p.id.month == this.date
      )[0].order_num;

      this.OrderObj.USDObj.AmountObj.saleAmount = data.all.filter(
        p => p.id.month == this.date
      )[0].goods_num;

      this.OrderObj.USDObj.SaleObj.saleSale = data.all.filter(
        p => p.id.month == this.date
      )[0].total_money;

      this.OrderObj.USDObj.Orderobj.shippedOrder =
        data.complete.filter(p => p.id.month == this.date).length == 0
          ? 0
          : data.complete.filter(p => p.id.month == this.date)[0].order_num;

      this.OrderObj.USDObj.AmountObj.shippedAmount =
        data.complete.filter(p => p.id.month == this.date).length == 0
          ? 0
          : data.complete.filter(p => p.id.month == this.date)[0].goods_num;

      this.OrderObj.USDObj.SaleObj.shippedSale =
        data.complete.filter(p => p.id.month == this.date).length == 0
          ? 0
          : data.complete.filter(p => p.id.month == this.date)[0].total_money;

      this.OrderObj.USDObj.Orderobj.unshippedOrder =
        this.OrderObj.USDObj.Orderobj.saleOrder -
        this.OrderObj.USDObj.Orderobj.shippedOrder;

      this.OrderObj.USDObj.AmountObj.unshippedAmount =
        this.OrderObj.USDObj.AmountObj.saleAmount -
        this.OrderObj.USDObj.AmountObj.shippedAmount;

      this.OrderObj.USDObj.SaleObj.unshippedSale =
        this.OrderObj.USDObj.SaleObj.saleSale -
        this.OrderObj.USDObj.SaleObj.shippedSale;

      this.OrderObj.USDObj.SaleObj.unpaySale =
        this.OrderObj.USDObj.SaleObj.saleSale -
        this.OrderObj.USDObj.SaleObj.receivedpaymentSale;
    },
    initBlankObj() {
      this.OrderObj = {
        USDObj: {
          Month: 0,
          AmountObj: {
            saleAmount: 0,
            shippedAmount: 0,
            unshippedAmount: 0,
            complaintAmount: 0
          },
          Orderobj: {
            saleOrder: 0,
            shippedOrder: 0,
            unshippedOrder: 0,
            complaintOrder: 0
          },
          SaleObj: {
            saleSale: 0,
            shippedSale: 0,
            unshippedSale: 0,
            complaintSale: 0,
            receivedpaymentSale: 0,
            unpaySale: 0
          }
        },
        RMBObj: {
          Month: 0,
          AmountObj: {
            saleAmount: 0,
            shippedAmount: 0,
            unshippedAmount: 0,
            complaintAmount: 0
          },
          Orderobj: {
            saleOrder: 0,
            shippedOrder: 0,
            unshippedOrder: 0,
            complaintOrder: 0
          },
          SaleObj: {
            saleSale: 0,
            shippedSale: 0,
            unshippedSale: 0,
            complaintSale: 0,
            receivedpaymentSale: 0,
            unpaySale: 0
          }
        },
        ShippingObj: {
          Month: 0,
          territory: 0,
          abroad: 0,
          total: 0
        }
      };
      this.CostObj = {
        SaleObj: {
          Month: 0,
          USD: {
            sale: 0,
            order: 0,
            amount: 0,
            customer: 0
          },
          RMB: {
            sale: 0,
            order: 0,
            amount: 0,
            customer: 0
          }
        },
        PurchaseObj: {
          Month: 0,
          sale: 0,
          order: 0,
          amount: 0,
          supplier: 0,
          saleDiscount: 0,
          shipping: 0,
          profit: 0
        }
      };

      this.OrderObj.USDObj.Month = this.date;

      this.OrderObj.RMBObj.Month = this.date;

      this.OrderObj.ShippingObj.Month = this.date;

      this.CostObj.SaleObj.Month = this.date;

      this.CostObj.PurchaseObj.Month = this.date;
    },
    exportPDF() {
      let table
      if (this.tabs == 0) {
        if (this.activeTab == 0) {
          table = $('.USDTable')[0].outerHTML;
        }
        else if (this.activeTab == 1) {
          table = $('.RMBTable')[0].outerHTML;
        }
        else if (this.activeTab == 2) {
          table = $('.ShipTable')[0].outerHTML;
        }
      } 
      else if (this.tabs == 1) {
        if (this.activeTab == 0) {
          table = $('.SaleTable')[0].outerHTML;
        }
        else if (this.activeTab == 1) {
          table += $('.PurchaseTable')[0].outerHTML;
        }
      }
      var parentdiv = $('<div></div>')
      parentdiv.attr('id', 'parentOut')
      $('.sys-page').after(parentdiv)
      $('#parentOut').append(table)
      $('#parentOut').css('height', 'auto')
      var canvas = document.createElement('canvas')
      canvas.width = parseFloat($('#parentOut')[0].scrollWidth)
      canvas.height = parseFloat($('#parentOut')[0].scrollHeight)
      html2canvas($('#parentOut')[0], {
        width: canvas.width,
        heigth: canvas.height
      }).then(canvas => {
        var imgData = canvas.toDataURL('image/jpeg', 1.0)
        var canWidth = canvas.width
        var canHeight = canvas.height
        var arrDPI = this.js_getDPI()
        var dpiX = 96
        var dpiY = 96
        if (arrDPI.length > 0) {
          dpiX = arrDPI[0]
          dpiY = arrDPI[1]
        }
        var doc = new JsPDF('1', 'pt', [canWidth, canHeight])
        doc.addImage(imgData, 'JPEG', 0, 0, canWidth, canHeight)
        if (this.tabs == 0) {
          doc.save("Orders" + `.pdf`)
          
        } else if (this.tabs == 1) {
          doc.save("Cost" + `.pdf`)
        }
        $('#parentOut').remove()
      })
    },
    js_getDPI() {
      var arrDPI = new Array()
      if (window.screen.deviceXDPI !== undefined) {
        arrDPI[0] = window.screen.deviceXDPI
        arrDPI[1] = window.screen.deviceYDPI
      } else {
        var tmpNode = document.createElement('DIV')
        tmpNode.style.cssText = 'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden'
        document.body.appendChild(tmpNode)
        arrDPI[0] = parseInt(tmpNode.offsetWidth)
        arrDPI[1] = parseInt(tmpNode.offsetHeight)
        tmpNode.parentNode.removeChild(tmpNode)
      }
      return arrDPI
    },
  },
  watch: {
    date: function() {
      this.getData();
    },
    wait: function() {
      if (this.wait[0] == true && this.wait[1] == true) {
        console.log("Temp Customer", this.tempCustomer);
        console.log("Temp Order", this.tempOrder);

        console.log("Result", this.tempCustomer.filter(p => this.tempOrder.customer_id == p.id))
      }
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
